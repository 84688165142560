import React, { Suspense, useEffect, useRef, useState } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import styled from "styled-components";
import sizeConfig from "../size-config";
import { Image } from "antd-mobile";
import Audio from "./Audio";
import { Box3, Vector3 } from "three";

function getUrlParam(k) {
  const reg = new RegExp(`(^|&)${k}=([^&]*)(&|$)`);
  const r = decodeURIComponent(window.location.search.substr(1)).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
const StyleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background-repeat: no-repeat;
  flex-direction: column;
  background-color: #1a1a1a;
  background-image: url("https://dadiwan.oss-cn-hangzhou.aliyuncs.com/assets/images/viewantique-04-img_bj400.png.jpg");
  background-size: 100% 100%;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }
  .hua-icon {
    position: absolute;
    bottom: 13vh;
    height: auto;
    display: flex;
    justify-content: center;
    animation: 2s rowLeft linear infinite;
    animation-direction: alternate;
    img {
      width: 80px;
    }
  }
  .hua-out {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  @keyframes rowLeft {
    0% {
      transform: translate3d(-30px, 0, 0);
    }

    100% {
      transform: translate3d(30px, 0, 0);
    }
  }
  .hua-text {
    width: 100%;
    position: absolute;
    bottom: 9vh;
    color: #fff;
    text-align: center;
  }
  .title-name {
    font-size: 30px;
    position: absolute;
    top: 19vh;
    text-align: center;
    width: 100%;
    color: #fff;
    font-family: AlimamaShuHeiTi;
    font-weight: bold;
    font-size: 51rpx;
    color: #000000;
    line-height: 83rpx;
    background: linear-gradient(0deg, #ffffff 0%, #ffd8ae 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .ip-post {
    margin-top: 50px;
    position: absolute;
    left: 6%;
    z-index: 999999;
    .ren-ip {
      position: absolute;
      width: 44px;
      height: auto;
    }
    .ren-s {
      width: 150px;
      height: auto;
      position: absolute;
      left: 70px;
      top: 2vh;
    }
  }
  .loading-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5em;
    color: white; /* 示例：白色字体 */
  }
`;

const Model = ({
  scale,
  onLoad,
  setModelCenter,
  url = "https://dadiwan.oss-cn-hangzhou.aliyuncs.com/assets/models/antique_mod01.FBX",
}) => {
  const fbx = useLoader(FBXLoader, url, (loader) => {
    // 模型加载完成时回调
    loader.manager.onLoad = onLoad;
  });

  // 使用 useRef 指向模型用于旋转
  const modelRef = useRef();

  // 使用 useFrame 在每帧更新时旋转模型
  useFrame(() => {
    if (modelRef.current) {
      // 在每帧推动 z 轴旋转
      modelRef.current.rotation.y += 0.01;
    }
  });

  useEffect(() => {
    // 计算模型的包围盒，并设置中心点
    const box = new Box3().setFromObject(fbx);
    const center = new Vector3();
    box.getCenter(center);
    setModelCenter(center);
    onLoad();
  }, [fbx, onLoad, setModelCenter]);

  return <primitive ref={modelRef} object={fbx} scale={scale} />;
};

const ModelViewer = ({ initialScale, minScale = 0.1, maxScale = 5 }) => {
  const controlsRef = useRef();

  const [pageData, setPageData] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const [modelCenter, setModelCenter] = useState(new Vector3());

  const onModelLoad = () => {
    setIsLoading(false);
  };

  const resetView = () => {
    if (controlsRef.current) {
      controlsRef.current.reset();
      // controlsRef.current.target.set(
      //   sizeConfig[getUrlParam("id") ?? "10"]?.["position"]
      // );
    }
  };

  useEffect(() => {
    if (controlsRef.current) {
      // 设置控件的目标为模型的中心
      controlsRef.current.target.copy(modelCenter);
      controlsRef.current.update();
    }
  }, [modelCenter]);

  useEffect(() => {
    fetch(
      `https://panos.rwone.xyz/app-api/ddw/relic/get?id=${
        getUrlParam("id") ?? 10
      }`,
      {
        method: "GET",
        headers: {
          "tenant-id": 151,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 0) {
          document.title = data.data.name;
          setPageData(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <StyleWrapper>
      <div className="detail_voice_audio">
        <Audio
          src={pageData?.audioIntroduction}
          cnSrc={pageData?.audioIntroduction}
          enSrc={pageData?.audioIntroductionEn}
        />
      </div>
      <div className="title-name">{pageData?.name}</div>
      <Canvas>
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <Suspense
          fallback={
            <Html center>
              <div></div>
            </Html>
          }
        >
          <Model
            scale={sizeConfig[getUrlParam("id") ?? "10"]?.["scale"]}
            url={pageData?.address}
            onLoad={onModelLoad}
            setModelCenter={setModelCenter}
          />
        </Suspense>

        {isLoading && (
          <Html center>
            <div className="loading-overlay">模型加载中</div>
          </Html>
        )}
        <OrbitControls
          ref={controlsRef}
          enableZoom
          maxDistance={maxScale}
          minDistance={minScale}
        />
      </Canvas>
      <div className="hua-out">
        <Image
          className="hua-icon "
          src="https://dadiwan.oss-cn-hangzhou.aliyuncs.com/assets/images/viewantique-04-img_huadongtishi.png"
        />
        <div className="hua-text">滑动查看细节</div>
      </div>
      <button
        onClick={resetView}
        style={{
          padding: "10px 20px",
          backgroundColor: "#ffd8ae",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          width: "100px",
          margin: "0 auto",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 10,
        }}
      >
        还原视图
      </button>
    </StyleWrapper>
  );
};

export default ModelViewer;
