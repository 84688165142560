import React, {
  useRef,
  Suspense,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import {
  Canvas,
  useLoader,
  extend,
  useFrame,
  useThree,
} from "@react-three/fiber";
import { FBXLoader } from "three-stdlib";
import { Html, PerspectiveCamera, Text } from "@react-three/drei";
import * as THREE from "three";
import { BoxGeometry, ShaderMaterial } from "three";
import gsap from "gsap";
import sizeConfig from "../size-config";
import { getUrlParam } from "../Model";
import { useGesture } from "@use-gesture/react";

// 自定义 ShaderMaterial
class FlowShaderMaterial extends ShaderMaterial {
  constructor() {
    super({
      uniforms: {
        time: { value: 0 },
        speed: { value: 1.0 },
        u_glowColor: { type: "c", value: new THREE.Color(0xffd700) }, // 金色
      },
      vertexShader: /* glsl */ `
        void main() {
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: /* glsl */ `
        uniform float time;
        uniform float speed;
        void main() {
          float glow = sin(time * speed) * 0.5 + 0.5;
          gl_FragColor = vec4(glow, glow, glow, 1.0);
        }
      `,
      transparent: true,
    });
  }
}

// 使用extend将FlowShaderMaterial添加到react-three-fiber的可用组件中
extend({ FlowShaderMaterial });

const CustomModel = ({ fbxPath, position, scale }) => {
  const fbx = useLoader(FBXLoader, fbxPath);
  fbx.scale.set(...scale);
  fbx.position.set(...position);

  // 计算模型的边界框（BoundingBox）
  const bbox = new THREE.Box3().setFromObject(fbx);

  // 计算模型的尺寸
  const size = new THREE.Vector3();
  bbox.getSize(size);
  return (
    <>
      <primitive object={fbx} />
    </>
  );
};

const ModelGroup = forwardRef(
  ({ modelPosition, modelScale, modelSrc }, ref) => {
    const camera = useThree((state) => state.camera);
    const controlsRef = useRef();
    const meshRef = useRef();
    const [isAutoRotating, setIsAutoRotating] = useState(true);
    // 在每帧更新时调用的函数，用于实现旋转动画
    useFrame(({ clock }) => {
      // 只有在允许自动旋转时才更新旋转
      if (isAutoRotating) {
        const elapsedTime = clock.getElapsedTime();
        meshRef.current.rotation.y = elapsedTime * 0.5; // 控制旋转的速度
      }
    });
    const [_modelScale, setModelScale] = useState(modelScale);
    const bind = useGesture({
      onPinch: ({ offset: [d] }) => {
        setModelScale([
          modelScale[0] + d / 400,
          modelScale[1] + d / 400,
          modelScale[2] + d / 400,
        ]); // 根据捏合的距离调整scale，这里的100是为了缩小d变化的比例，你可以根据需要调整
      },
    });

    const zoomIn = (position, lookAt) => {
      gsap.to(camera.position, {
        x: position[0],
        y: position[1],
        z: position[2],
        duration: 2,
        onUpdate: () => camera.updateProjectionMatrix(),
      });
      gsap.to(controlsRef.current.target, {
        x: lookAt[0],
        y: lookAt[1],
        z: lookAt[2],
        duration: 2,
      });
    };
    useImperativeHandle(ref, () => ({
      changeTestFunc,
    }));
    const detailPosition = new THREE.Vector3(0.03, 1.2, 1.4);
    const position = [detailPosition.x, detailPosition.y, detailPosition.z];
    const lookAt = detailPosition;
    const changeTestFunc = () => {
      zoomIn(position, lookAt);
    };
    // 缩放并定位模型
    return (
      <PerspectiveCamera
        ref={controlsRef}
        global
        speed={4}
        config={{ mass: 1, tension: 250, friction: 25 }}
        zoom={1}
        rotation={[0, 0, 0]}
        polar={[0, Math.PI / 4]}
      >
        <mesh {...bind()} ref={meshRef}>
          <CustomModel
            fbxPath={modelSrc}
            position={modelPosition}
            scale={_modelScale}
          />
        </mesh>
      </PerspectiveCamera>
    );
  }
);

const Scene = forwardRef((props, ref) => {
  const modelPosition = sizeConfig[getUrlParam("id")]?.["position"] ?? [
    0, -2, 0,
  ]; // 根据实际情况调整模型位置
  const modelScale = sizeConfig[getUrlParam("id")]?.["scale"] ?? [4, 4, 4]; // 根据实际情况调整模型缩放
  const { curStatus, modelSrc } = props;

  return (
    <Canvas
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100vw",
        height: `${curStatus === "expand" ? "100vh" : "40vh"}`,
        zIndex: 777,
      }}
    >
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <Suspense fallback={null}>
        <ModelGroup
          modelSrc={modelSrc}
          modelScale={modelScale}
          modelPosition={modelPosition}
        ></ModelGroup>
      </Suspense>
    </Canvas>
  );
});

export default Scene;
