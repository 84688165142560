export default {
  19: {
    position: [0, 0, 0],
    scale: [0.2, 0.2, 0.2],
  },
  18: {
    position: [0, 0, 0],
    scale: [0.4, 0.4, 0.4],
  },
  17: {
    position: [0, 0, 0],
    scale: [0.4, 0.4, 0.4],
  },
  16: {
    position: [0, 0, 0],
    scale: [0.4, 0.4, 0.4],
  },
  15: {
    position: [0, 0, 0],
    scale: [0.2, 0.2, 0.2],
  },
  14: {
    position: [0, 0, 0],
    scale: [0.2, 0.2, 0.2],
  },
  13: {
    position: [0, -0.2, 0],
    scale: [0.4, 0.4, 0.4],
  },
  12: {
    position: [0, -0.2, 0],
    scale: [0.3, 0.3, 0.3],
  },
  10: {
    position: [0, 1, 0],
    scale: [26, 26, 26],
  },
  9: {
    position: [0, 1, 0],
    scale: [5, 5, 5],
  },
  8: {
    position: [0, 1, 0],
    scale: [5, 5, 5],
  },
  7: {
    position: [0, 1, 0],
    scale: [6, 6, 6],
  },
  6: {
    position: [0, 1.4, 0],
    scale: [3, 3, 3],
  },
  5: {
    position: [0, 1, 0],
    scale: [5, 5, 5],
  },
  3: {
    position: [0, 1, 0],
    scale: [5, 5, 5],
  },
  4: {
    position: [0, 1, 0],
    scale: [6, 6, 6],
  },
  1: {
    position: [0, 1, 0],
    scale: [6, 6, 6],
  },
};
